import { Directive, ElementRef, AfterViewInit, OnDestroy, Input, AfterViewChecked } from '@angular/core';
import { AutoSizeHelper } from '../helpers/autoSizeHelper';

@Directive({
    selector: '[autoSize]',
    exportAs: 'autoSizeDirective' //the name of the variable to access the directive using ViewChild()
})
export class AutoSizeDirective implements AfterViewInit, OnDestroy, AfterViewChecked {

    @Input() autoSize: boolean;

    constructor(private _element: ElementRef) {
    }

    update() {
        AutoSizeHelper.update(this._element.nativeElement);
    }

    ngAfterViewInit(): any {
        AutoSizeHelper.applyToElement(this._element.nativeElement);
    }

    ngAfterViewChecked(): any {
        setTimeout(() => {
            this.update();
        });
    }

    ngOnDestroy() {
        AutoSizeHelper.destroy(this._element.nativeElement);
    }
}
