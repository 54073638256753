import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Config, Level, Logger } from 'ngx-myia-core';
import { OnlineMeetingProvider } from 'ngx-myia-live-meeting';
import { configureReduxLogger, ReduxStore } from 'ngx-myia-redux';
import { IToastButton, ToastManager } from 'ngx-myia-toast';
import { DevToolsService } from '../services/devToolsService';
import { TokenService } from '../services/tokenService';
import { getDropDownValuesFromEnum, IDropDownValue } from './controls/inputDropDown';

@Component({
    selector: 'dev-tools',
    styleUrls: ['./devTools.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="_devToolsMenu_">
            <div class="panelTitle">Development tools</div>
            <div class="closeBtnWrapper">
                <button class="btnPlain withIcon" type="button" (click)="closeDevTools()">
                    <svg-icon name="cancel"></svg-icon>
                </button>
            </div>
            <div class="section">
                <input-dropdown [value]="loggerLevel" label="Log level" [items]="loggerLevels" [itemTitlePath]="'value'" (valueChange)="loggerLevelChanged($event)"></input-dropdown>
            </div>
            <div class="section">
                <div class="title">Token management:</div>
                <button type="button" (click)="invalidateToken(true, false)">Invalidate token</button>
                <button type="button" (click)="invalidateToken(false, true)">Invalidate refresh token</button>
            </div>
            <div class="section">
                <div class="title">Live states</div>
                <button type="button" (click)="setLiveState('notStarted')">Not started</button>
                <button type="button" (click)="setLiveState('finished')">Finished</button>
                <button type="button" (click)="setLiveState('waiting')">Waiting</button>
                <button type="button" (click)="setLiveState('streaming')">Streaming</button>
                <button type="button" (click)="setLiveState('speedBusinessMeeting')">Speed Meeting (Jitsi)</button>
                <button type="button" (click)="setLiveState('meeting')">Meeting (Jitsi)</button>
                <button type="button" (click)="setLiveState('meeting', jitsiJaasProps)">Meeting (Jaas)</button>
            </div>
            <div class="section">
                <div class="title">Toasts:</div>
                <div>
                    <button type="button" (click)="showToast('info')">Info</button>
                    <button type="button" (click)="showToast('warning')">Warning</button>
                    <button type="button" (click)="showToast('error')">Error</button>
                    <button type="button" (click)="showToast('success')">Success</button>
                </div>
                <div class="subTitle">With toast key:</div>
                <div>
                    <button type="button" (click)="showToast('info', '_toastKey_')">Info</button>
                    <button type="button" (click)="showToast('warning', '_toastKey_')">Warning</button>
                    <button type="button" (click)="showToast('error', '_toastKey_')">Error</button>
                    <button type="button" (click)="showToast('success', '_toastKey_')">Success</button>
                </div>
                <div class="subTitle">Custom icon:</div>
                <div>
                    <button type="button" (click)="showCustomToast()">Custom info</button>
                </div>
                <div class="subTitle">App updated message:</div>
                <div>
                    <button type="button" (click)="showAppUpdated()">Show</button>
                </div>
            </div>
        </div>
    `
})
export class DevToolsComponent {
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    loggerLevel: IDropDownValue;
    loggerLevels: Array<IDropDownValue>;
    jitsiJaasProps = {
        provider: OnlineMeetingProvider.Jaas,
        roomId: 'vpaas-magic-cookie-fadcd1a22ddf43e8a3f87a5e62ab94cc/SampleAppConsistentTroublesShockConsiderably'
    };

    constructor(private _tokenService: TokenService, private _toastManager: ToastManager, private _store: ReduxStore, private _logger: Logger, private _devToolsService: DevToolsService) {
        this.loggerLevels = getDropDownValuesFromEnum(Level);
        const configLevel = Config.get<string>('logLevel');
        this.loggerLevel = this.loggerLevels.find(l => l.value === configLevel);
    }


    loggerLevelChanged(level: IDropDownValue) {
        Config.set('logLevel', level.value);
        this._logger.level = Level[level.value];
        configureReduxLogger(this._logger.level >= Level.INFO);
    }

    invalidateToken(invalidateToken: boolean, invalidateRefreshToken: boolean) {
        this._tokenService._test_invalidate_token(invalidateToken, invalidateRefreshToken);
        if (invalidateToken) {
            this._toastManager.info('Token invalidated.');
        }
        if (invalidateRefreshToken) {
            this._toastManager.info('Refresh token invalidated.');
        }
    }

    closeDevTools() {
        this.close.emit();
    }

    showToast(toastType: string, toastKey?: string) {
        const options: any = {};
        if (toastKey) {
            options.toastKey = toastKey;
        }
        this._toastManager[toastType]('Test toast: ' + toastType, options);
    }

    showCustomToast() {
        const buttons: Array<IToastButton> = [
            {
                title: 'Update app', clickHandler: (toast: any) => {
                    this._toastManager.clearToast(toast);
                    this._logger.log('Update button clicked.');
                }
            }
        ];
        this._toastManager.info('There is update available.', {toastKey: '_toastKey_custom_', timeOut: 0, icon: 'update', iconPrefix: 'svgIcon', buttons});
    }

    showAppUpdated() {
        window.postMessage('MYIA_PWA_UPDATED', '*');
    }

    setLiveState(state: string, props?: any) {
        this._devToolsService.liveStateDebug.next(state);
        this._devToolsService.liveStateDebugProps.next(props);
        this._devToolsService.liveStateDebugActivatedAt = new Date();
    }
}

