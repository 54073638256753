import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CultureService } from 'ngx-myia-localization';
import { StatusBarManager } from '../services/statusBarManager';

@Component({
    selector: 'top-bar-culture-switch',
    styleUrls: ['./topBarCultureSwitch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <button class="topBarButton buttonCultureSwitch" (click)="switchCulture()" [tooltip]="'Menu|ChangeLanguage'|trans" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipOffset="0,10" tooltipContainer=".topBarRoot" [tooltipDontHideOnChange]="true"><span
                class="cultureBtn icon" *ngIf="!changingCulture">{{cultureService.currentCulture}}</span><progress-indicator-circle *ngIf="changingCulture" indicatorClass="extraSmall cultureLoader"></progress-indicator-circle><label>{{'Menu|ChangeLanguage'| trans}}</label></button>
    `,
})
export class TopBarCultureSwitchComponent {
    changingCulture: boolean = false;

    constructor(public cultureService: CultureService, private _statusBarManager: StatusBarManager, private _changeDetectorRef: ChangeDetectorRef) {
    }

    switchCulture() {
        // TODO implement switch using selection from list/combo
        let userLang = this.cultureService.currentCulture;
        if (userLang === 'en') {
            userLang = 'cs';
        } else {
            userLang = 'en';
        }
        this.changingCulture = true;
        this._changeDetectorRef.markForCheck();
        this.cultureService.use(userLang).subscribe(() => {
            this.changingCulture = false;
            this._changeDetectorRef.markForCheck();
        });
    }
}


