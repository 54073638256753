import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreUIModule } from 'ngx-myia-core-ui';
import { HttpModule } from 'ngx-myia-http';
import { cultureReducerKey, cultureReducers, LocalizationModule } from 'ngx-myia-localization';
import { ToastModule } from 'ngx-myia-toast';
import { AutoSizeDirective } from './components/autoSizeDirective';
import { CalendarIconComponent } from './components/calendarIcon';
import { ControlMessagesComponent } from './components/controlMessages';
import { InputFileComponent } from './components/controls/inputFile';
import { InputMultiSelectionComponent } from './components/controls/inputMultiSelection';
import { InputMultiSelectionItemPipe } from './components/controls/inputMultiSelectionItemPipe';
import { InputTextFieldComponent } from './components/controls/inputTextField';
import { InputTextFieldHasValuePipe } from './components/controls/inputTextFieldHasValuePipe';
import { DropEffectDirective } from './components/dropEffectDirective';
import { DROPDOWN_DIRECTIVES } from './components/ng2-bootstrap/dropdown';
import { ReduxFeatureModule, ReduxModule, ReduxStore } from 'ngx-myia-redux';
import { TooltipModule } from 'ngx-myia-tooltip';
import { InputCheckboxComponent } from './components/controls/inputCheckbox';
import { InputDropDownComponent } from './components/controls/inputDropDown';
import { DevToolsComponent } from './components/devTools';
import { CheckWebUrlPipe } from './components/pipes/checkWebUrlPipe';
import { SafePipe } from './components/pipes/safePipe';
import { SideBarComponent } from './components/sideBar';
import { SideBarItemComponent } from './components/sideBarItem';
import { StatusBarComponent } from './components/statusBar';
import { TopBarComponent } from './components/topBar';
import { TopBarCultureSwitchComponent } from './components/topBarCultureSwitch';
import { ValidationErrorsPipe } from './components/validationErrorsPipe';
import authReducers, { authReducerKey } from './redux/authReducers';
import statusBarReducers from './redux/statusBarReducers';
import { ContextMenuDirective } from './components/context-menu/contextMenuDirective';
import { ContextMenuHolderComponent } from './components/context-menu/contextMenuHolder';


/* Redux configuration - must be provided as factory to be AOT compatible */
export function provideReducers() {
    return [authReducers, cultureReducers, statusBarReducers];
}
export function providePersistedReducersNames() {
    return [authReducerKey, cultureReducerKey];
}

let done = false;

@NgModule({
    imports: [
        CommonModule,
        CoreUIModule,
        FormsModule,
        HttpModule,
        LocalizationModule,
        ReactiveFormsModule,
        ReduxModule.forChild(provideReducers, providePersistedReducersNames),
        RouterModule,
        ToastModule,
        TooltipModule,
    ],
    declarations: [
        AutoSizeDirective,
        CalendarIconComponent,
        CheckWebUrlPipe,
        ContextMenuDirective,
        ContextMenuHolderComponent,
        ControlMessagesComponent,
        DevToolsComponent,
        DROPDOWN_DIRECTIVES,
        DropEffectDirective,
        InputCheckboxComponent,
        InputDropDownComponent,
        InputFileComponent,
        InputMultiSelectionComponent,
        InputMultiSelectionItemPipe,
        InputTextFieldComponent,
        InputTextFieldHasValuePipe,
        SafePipe,
        SideBarComponent,
        SideBarItemComponent,
        StatusBarComponent,
        TopBarComponent,
        TopBarCultureSwitchComponent,
        ValidationErrorsPipe,
    ],
    exports: [
        AutoSizeDirective,
        CalendarIconComponent,
        CheckWebUrlPipe,
        ContextMenuDirective,
        ContextMenuHolderComponent,
        ControlMessagesComponent,
        DevToolsComponent,
        DROPDOWN_DIRECTIVES,
        DropEffectDirective,
        InputCheckboxComponent,
        InputDropDownComponent,
        InputFileComponent,
        InputMultiSelectionComponent,
        InputTextFieldComponent,
        InputTextFieldHasValuePipe,
        SafePipe,
        SideBarComponent,
        SideBarItemComponent,
        StatusBarComponent,
        TopBarComponent,
        TopBarCultureSwitchComponent,
        ValidationErrorsPipe,
    ],
    providers: [
        // all services are singletons with @Injectable({ providedIn: 'root' })
    ],
})
export class ViewSharedComponentsModule extends ReduxFeatureModule {
    constructor(reduxStore: ReduxStore, featureInjector: Injector) {
        super(done ? null : reduxStore, featureInjector);
        done = true;
    }
}

