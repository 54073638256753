import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { getFormControlErrors } from 'ngx-myia-core';
import { LocalizationService } from 'ngx-myia-localization';
import { ValidationService } from '../services/validationService';

export interface IValidationErrorsOptions {
    html: boolean; // return string containing formatted html
}

@Pipe({
    name: 'validationErrors',
    pure: false // required to update the value when the promise is resolved
})
export class ValidationErrorsPipe implements PipeTransform {

    constructor(private _validationService: ValidationService, private _localizationService: LocalizationService) {
    }

    transform(control: AbstractControl, options?: IValidationErrorsOptions): Array<string> | string {
        if (control) {
            const messages = getFormControlErrors(control).map(err => this._validationService.getValidatorErrorMessage(err));
            if (options && options.html) {
                return messages.map(m => `<div class="msg">${this._localizationService.translate(m)}</div>`).join('');
            }
            return messages;
        }
        return null;
    }
}
