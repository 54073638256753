import { Injectable } from '@angular/core';
import { Observable, of, Observer, from } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ExpressionService {
    private _jexl: any;

    evaluate<T>(expression: string, context: any): Observable<T> {
        return this.getJexl().pipe(
            mergeMap(jexl => {
                return from(jexl.eval(expression, context)) as Observable<T>;
            }),
            // tap(result => {
            //     console.log('expr:' + result);
            // })
        );
    }

    compile(expression: string): Observable<string> {
        return this.getJexl().pipe(
            map(jexl => {
                try {
                    jexl.compile(expression);
                }
                catch(err) {
                    return err;
                }
                return null;
            })
        );
    }

    private getJexl(): Observable<any> {
        if (!this._jexl) {
            this._jexl = new Observable((observer: Observer<any>) => {
                    import('jexl').then(jexlLib => {
                    // expressions evaluator
                    // add supported transforms
                    // Transform
                    const jexl = (jexlLib as any).default;
                    jexl.addTransform('empty', (val: any) => {
                        if (typeof val === 'string') {
                            return !val;
                        }
                        return val === undefined || val === null;
                    });
                    observer.next(jexl);
                    observer.complete();
                });
            });
        }
        return this._jexl;
    }

}
