import { Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'control-messages',
    styleUrls: ['./controlMessages.component.scss'],
    template: `<div class="controlMessage" *ngIf="messages?.length">
                    <div class="msg" *ngFor="let msg of messages; trackBy: trackMessage">{{msg|trans}}</div>
                </div>`
})
export class ControlMessagesComponent {
    @Input() messages: Array<string>;

    @HostBinding('class') hostClasses = 'controlMessagesContainer';

    trackMessage(index: number, msg: string): string {
        return msg;
    }
}
