import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Logger } from 'ngx-myia-core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/authService';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private _authService: AuthService, private _logger: Logger) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const result = this._authService.isAuth();
        result.subscribe((isAuth: boolean) => {
            if (!isAuth) {
                this._logger.info('User is not authenticated -> redirect to login...');
                this._authService.login();
            }
        });
        return result;
    }
}
