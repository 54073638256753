import { Component, Input, Output, EventEmitter, OnInit, HostBinding } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { getNewComponentId } from 'ngx-myia-core';
import { LocalizationService } from 'ngx-myia-localization';
import { ToastManager } from 'ngx-myia-toast';

export interface IMultiSelectionItem {
    label: string,
    value: any
}

const multiSelectionLimitReachedToastKey = 'multiSelectionLimitReachedToastKey';

@Component({
    selector: 'input-multi-selection',
    styleUrls: ['./inputMultiSelection.component.scss'],
    template: `
                <div [ngClass]="{modified: isModified, readOnly: readonly, invalid: control?.invalid, dirty: control?.dirty}">
                  <label *ngIf="label">{{label}}</label>
                  <div class="checkBoxesBlock">
                      <input-checkbox *ngFor="let choice of items; trackBy: trackItem" classNames="classic" [textStates]="false" [label]="choice[itemTitlePath]" [value]="choice | inputMultiSelectionItem: value" [isDisabled]="disabled || readonly" [canValueChange]="canValueChange(choice)" (valueChange)="valueChanged($event, choice)"></input-checkbox>
                  </div>
                </div>
               `
})
export class InputMultiSelectionComponent implements OnInit {
    @Input() set value(val: any) {
        if (this._value !== val) {
            this._value = val || [];
            if (this.control) {
                this.control.setValue(val);
            }
            //console.log('MultiSelection value changed: ' + (val ? JSON.stringify(val) : val));
        }
    }

    get value(): any {
        return this._value;
    }

    @Input() label: string;
    @Input() items: Array<IMultiSelectionItem>;
    @Input() displayType: string;
    @Input() maxSelected: number;
    @Output() valueChange: any = new EventEmitter<any>();
    @Input() disabled: boolean;
    @Input() validator: any;
    @Input() readonly: boolean;
    @Input() isRequired: boolean;
    @Input() isModified: boolean;
    @Input() itemTitlePath: string = 'label';
    @Input() formGroupRef: FormGroup;
    @Input() fieldName: string;

    @Input() set classNames (value: string) {
        this._classNames = value;
        this.updateHostClasses();
    }

    @HostBinding('class') hostClasses: string;

    public id: string;
    public control: FormControl;

    private _value: any;
    private _classNames: string;

    constructor(private _toastManager: ToastManager, private _localizationService: LocalizationService) {
        this.id = getNewComponentId();
    }

    ngOnInit(): void {
        this.updateHostClasses();
        const validators = this.validator ? [this.validator] : [];
        if (this.isRequired) {
            validators.push(Validators.required);
        }
        this.control = new FormControl({value: this._value, disabled: this.disabled}, Validators.compose(validators));

        if (this.formGroupRef) {
            this.formGroupRef.addControl(this.fieldName || getNewComponentId(), this.control);
        }
    }

    setValue(newValue: any) {
        if (this._value !== newValue) {
            this._value = newValue;
            if (this.control) {
                this.control.setValue(newValue);
                this.control.markAsTouched();
            }
            this.valueChange.emit(newValue);
            //console.log('MultiSelection value changed (internal): ' + (newValue? JSON.stringify(newValue) : newValue));
        }
    }

    canValueChange(item: IMultiSelectionItem) {
        return (newValue: boolean, clicked: boolean) => {
            const canChange = !newValue || this.isItemSelected(item) || !this.maxSelected || this.value.length < this.maxSelected;
            if (!canChange && clicked) {
                this._toastManager.warning(this._localizationService.translate('MultiSelection|Selection_Max_Limit_Reached', {cnt: this.maxSelected}), {toastKey: multiSelectionLimitReachedToastKey});
            }
            return canChange;
        }
    }

    valueChanged(newValue: boolean, item: IMultiSelectionItem) {
        if (newValue && this.maxSelected && this.value.length >= this.maxSelected) {
            return;
        }
        const value = this.value && this.value.indexOf ? [...this.value] : [];
        if (newValue) {
            value.push(item);
        }
        else {
            const itemIndex = value.indexOf(item);
            if (itemIndex >= 0) {
                value.splice(itemIndex, 1);
            }
            else {
                return;
            }
        }
        this.setValue(value);
    }

    trackItem(index: number, item: {label: string, value: any}): string {
        return item.value;
    }

    private isItemSelected(choice: any): boolean {
        return this.value && this.value.indexOf && this.value.indexOf(choice) > -1;
    }

    private updateHostClasses() {
        this.hostClasses = ('multiselect ' + (this._classNames + ' ' || '') + (this.displayType ?  this.displayType + ' ' : '')).trim();
    }
}
