import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Injector, Renderer2 } from '@angular/core';
import { setElementClass } from 'ngx-myia-core';
import { ReduxStore, ReduxView } from 'ngx-myia-redux';
import { IStatusBarMessage } from '../entities/statusBarMessage.interface';
import { IStatusBarState, statusBarReducerKey } from '../redux/statusBarReducers';

@Component({
    selector: 'status-bar',
    styleUrls: ['./statusBar.component.scss'],
    template: `
                <div class="messages">
                    <div class="msgCount" *ngIf="statusBarMessages && statusBarMessages.length > 1" (click)="toggle()">{{statusBarMessages.length}}</div>
                    <div class="msg" *ngFor="let message of statusBarMessages; trackBy:trackByMessage" [ngClass]="{centered: message.centered}">{{message.messageText| trans}}</div>
                </div>
                <div class="busyLine">
                    <progress-indicator-bar *ngIf="showBusyLine" indicatorClass="blockLoader"></progress-indicator-bar>
                </div>
              `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusBarComponent extends ReduxView implements AfterViewInit {
    @HostBinding('class') hostClasses = 'statusBar';

    public statusBarMessages: ReadonlyArray<IStatusBarMessage>;
    public showBusyLine: boolean;

    private _isExpanded: boolean;
    private _isInitialized: boolean;

    constructor(injector: Injector, private _store: ReduxStore, private _renderer: Renderer2, private _elementRef: ElementRef) {
        super(injector);
        this.attachToStore(_store, [statusBarReducerKey]);
    }

    ngAfterViewInit() {
        // activate status bar animation to avoid animating of initial 'hidden' state
        if (this.showStatusBar()) {
            this._renderer.addClass(this._elementRef.nativeElement, 'ready');
        }
        this._isInitialized = true;
    }

    mapStateToProps(state: any) {
        const statusBarState = state[statusBarReducerKey] as IStatusBarState;
        this.statusBarMessages = this.getPropertyFromState(this.statusBarMessages, statusBarState.messages);
        this.showBusyLine = this.getPropertyFromState(this.showBusyLine, statusBarState.showBusyLine);
        return this.propertiesUpdated;
    }

    updateView() {
        setElementClass(this._renderer, this._elementRef.nativeElement, 'ready', this._isInitialized);
        setElementClass(this._renderer, this._elementRef.nativeElement, 'show', this.showStatusBar());
        setElementClass(this._renderer, this._elementRef.nativeElement, 'expanded', this._isExpanded);
        setElementClass(this._renderer, this._elementRef.nativeElement, 'withMessages', this.statusBarMessages && !!this.statusBarMessages.length);
        setElementClass(this._renderer, this._elementRef.nativeElement, 'withBusyLine', this.showBusyLine);
        super.updateView();
    }

    trackByMessage(index: number, message: IStatusBarMessage): any {
        return message.messageKey;
    }

    toggle() {
        this._isExpanded = !this._isExpanded;
        this.updateView();
    }

    private showStatusBar(): boolean {
        return this.showBusyLine || (this.statusBarMessages && !!this.statusBarMessages.length);
    }
}
