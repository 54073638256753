import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from 'ngx-myia-core';
import { DialogManager } from 'ngx-myia-dialog';
import { BackendService, IAuthService, IAuthState } from 'ngx-myia-http';
import { ReduxStore } from 'ngx-myia-redux';
import { ToastManager } from 'ngx-myia-toast';
import { LocalizationService } from 'ngx-myia-localization';
import * as jwt_decode from 'jwt-decode';
import { Observable, Observer } from 'rxjs';
import { authLogoutAction } from '../redux/authActions';
import { authReducerKey } from '../redux/authReducers';
import { AccessDeniedPath, LoginRoutePath, LogoutRoutePath } from '../routes.paths';
import { TokenService } from './tokenService';

/**
 * Auth service
 */
@Injectable({ providedIn: 'root' })
export class AuthService implements IAuthService {

    private _tokenRefreshFailedToastKey = '_tokenRefreshFailedToast';


    constructor(private _router: Router, private _location: Location, private _store: ReduxStore, private _backendService: BackendService, private _dialogManager: DialogManager, private _toastManager: ToastManager, private _localizationService: LocalizationService, private _tokenService: TokenService, private _logger: Logger) {
        _backendService.login.subscribe(() => {
            this._dialogManager.closeCurrentDialog();
            this._toastManager.clearToastByKey(this._tokenRefreshFailedToastKey, true);
            this._toastManager.warning(this._localizationService.translate('Authentication|Session_Expired_Please_Login'), { toastKey: this._tokenRefreshFailedToastKey });
            this.login();
        });
        _backendService.accessDenied.subscribe(() => {
            this.accessDenied();
        });
    }

    /**
     * Return users authenticate state.
     * @return {boolean} - is user authenticated
     */
    isAuth(): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            const authState: IAuthState = this._store.getState(authReducerKey);
            if (authState) {
                const isLoading = authState._isLoading || authState._isRehydrating;
                if (isLoading) {
                    // wait for auth result
                    this._logger.log('Waiting for auth result ...');
                    const sub = this._store.subscribe((state: any) => {
                        const authState: IAuthState = state[authReducerKey];
                        const isLoading = authState._isLoading || authState._isRehydrating;
                        if (!isLoading) {
                            sub(); // release store subscription
                            const isAuth = authState.isAuth;
                            this._logger.info('Auth result: ' + isAuth);
                            observer.next(isAuth);
                            observer.complete();
                        }
                    }, [authReducerKey]);
                }
                else {
                    const isAuth = authState.isAuth;
                    if (isAuth) {
                        // check expiration of token
                        const decodedToken = this.getDecodedToken(authState);
                        if (decodedToken) {
                            if (this.isTokenValid(authState)) {
                                observer.next(true);
                                observer.complete();
                            }
                            else {
                                // try to refresh token
                                this._tokenService.refreshToken().subscribe(() => {
                                    // check refreshed access token
                                    const authState: IAuthState = this._store.getState(authReducerKey);
                                    observer.next(this.isTokenValid(authState));
                                    observer.complete();

                                }, () => {
                                    // refresh failed
                                    observer.next(false);
                                    observer.complete();
                                });
                            }
                        }
                        else {
                            // unknown token
                            observer.next(false);
                            observer.complete();
                        }
                    }
                    else {
                        observer.next(false);
                        observer.complete();
                    }
                }
            }
            else {
                this._logger.log('Authentication data not found.');
                observer.next(false);
                observer.complete();
            }
        });
    }

    /**
     * Redirect to login view.
     */
    login() {
        // check if router is already at /login page
        const loginTree = this._router.createUrlTree(['/', LoginRoutePath]);
        if (this._router.isActive(loginTree, false)) {
            // already at login page
            return;
        }
        // navigate to /login with return url
        let returnUrl = this._location.path();
        // filter some return urls
        if (returnUrl === '/resetPassword') {
            returnUrl = null;
        }
        this._router.navigate(['/', LoginRoutePath ], returnUrl ? {queryParams: {returnUrl: encodeURIComponent(returnUrl)}} : {});
    }

    /**
     * Redirect to logout view.
     */
    logout() {
        this._store.dispatch(authLogoutAction());
        this._router.navigate(['/', LogoutRoutePath]);
    }


    accessDenied() {
        this._router.navigate(['/', AccessDeniedPath]);
    }

    private getDecodedToken(authState: IAuthState): any {
        if (authState) {
            const token = authState.token;
            let decoded;
            try {
                decoded = (jwt_decode as any).default(token);
            }
            catch (e) {
                // cant decode token, it's probably invalid
            }
            // this._logger.log(decoded);
            return decoded;
        }
        return null;
    }

    private isTokenValid(authState: any): boolean {
        // check expiration of token
        const decodedToken = this.getDecodedToken(authState);
        return decodedToken && decodedToken.exp >= new Date().getTime() / 1000;
    }
}
