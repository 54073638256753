import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmitterService, getElementScrollbarWidth, getNewComponentId, getParentScroll, IFRAME_CLICKED } from 'ngx-myia-core';

export interface IDropDownValue {
    label: string;
    value: string;
}

export function getDropDownValuesFromEnum(enumType: any, labelMapping?: any) : Array<IDropDownValue> {
    return Object.keys(enumType).filter(key => !isNaN(Number(enumType[key]))).map(l => { return { label: labelMapping ? labelMapping(enumType[l]) : enumType[l], value: l};});
}

@Component({
    selector: 'input-dropdown',
    styleUrls: ['./inputDropDown.component.scss'],
    template: `
                <div dropdown (click)="$event.preventDefault()" [(isOpen)]="isOpen" [ngClass]="{modified: isModified, readOnly: readonly, invalid: control?.invalid, dirty: control?.dirty}">
                  <a href [id]="id" dropdownToggle [disabled]="disabled" [ngClass]="{empty: null === value && !nullValueTitle}">
                    <svg-icon *ngIf="iconPath && itemByValue(value)" name="{{itemByValue(value)[iconPath]}}"></svg-icon>
                    <span [innerHTML]="(itemByValue(value) ? itemByValue(value)[itemTitlePath] : (nullValueTitle || '&nbsp;'))|trans|sanitizeHtml"></span>
                  </a>
                  <input #inputEl type="text" autocomplete="off" tabindex="-1"
                       (keydown)="inputEvent($event)"
                       (keyup)="inputEvent($event, true)"
                       [disabled]="disabled"
                       class="searchBox"
                       *ngIf="editable"
                       placeholder="{{label || ''}}">
                  <label *ngIf="label">{{label}}</label>
                  <ul *ngIf="!readonly" dropdownMenu class="dropdown-menu" [attr.aria-labelledby]="id" #itemsContainer>
                      <li *ngIf="nullValueTitle" (click)="itemClicked($event, null)">
                        <a class="dropdown-item" href="#" [ngClass]="{selected: null === value, active: null === activeItem}"><span>{{nullValueTitle}}</span></a>
                      </li>
                      <li *ngFor="let choice of items; trackBy: trackItem" (click)="itemClicked($event, choice)">
                        <a class="dropdown-item" href="#" [ngClass]="{selected: choice === itemByValue(value), active: choice === activeItem}"><svg-icon *ngIf="iconPath" name="{{choice[iconPath]}}"></svg-icon><span>{{choice[itemTitlePath]|trans}}</span></a>
                      </li>
                  </ul>
                </div>
               `
})
export class InputDropDownComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit, AfterViewChecked {
    @Input() set value(val: any) {
        this._value = val;
        if (this.control) {
            this.control.setValue(this._value);
        }
    }

    get value(): any {
        return this._value;
    }

    @Input() label: string;
    @Input() items: Array<IDropDownValue | any>;
    @Output() valueChange: any = new EventEmitter<any>();
    @Input() disabled: boolean;
    @Input() editable: boolean;
    @Input() validator: any;
    @Input() readonly: boolean;
    @Input() isRequired: boolean;
    @Input() isModified: boolean;
    @Input() itemTitlePath: string = 'label';
    @Input() valuePath: string;
    @Input() nullValueTitle: string;
    @Input() iconPath: string;
    @Input() formGroupRef: FormGroup;
    @Input() fieldName: string;

    @Input() set classNames (value: string) {
        this._classNames = value;
        this.updateHostClasses();
    }

    @HostBinding('class') hostClasses: string;

    @ViewChild('inputEl', {static: false}) _inputEl: ElementRef;
    @ViewChild('itemsContainer', {static: false}) _itemsContainer: ElementRef;


    public get isOpen(): boolean {
        return this._isOpen;
    }

    public set isOpen(isOpen: boolean) {
        if (this._isOpen !== isOpen) {
            this._isOpen = isOpen;
            if (this._isOpen) {
                if (this._parentScroll) {
                    this._lastScrollPos = this._parentScroll.scrollTop; // remember scroll pos when drop-down opened to detect parent scroll caused by user
                }
                this.activeItem = this.value;
                if (this._inputEl) {
                    this._inputEl.nativeElement.focus();
                }
                if (this._itemsContainer) {
                    this._itemsContainer.nativeElement.style.opacity = 0.0;
                    setTimeout(() => {
                        this.updateDropdownListPosition();
                        this.ensureHighlightVisible();
                        this._itemsContainer.nativeElement.style.opacity = 1.0;
                    }, 0);
                }
            }
        }
    }

    public id: string;
    public control: FormControl;
    public activeItem: any;

    private _value: any;
    private _iframeClickedSubscription: any;
    private _classNames: string;
    private _isOpen: boolean = false;
    private _clearanceTimeout: any;
    private _parentScroll: HTMLElement;

    private _lastScrollPos: number;
    private onParentScrollBind: EventListener = this.onParentScroll.bind(this);

    constructor(private _element: ElementRef, private _changeDetectorRef: ChangeDetectorRef, private _zone: NgZone) {
        this.id = getNewComponentId();
        this._iframeClickedSubscription = EmitterService.getEvent(IFRAME_CLICKED).subscribe(() => {
            if (this.isOpen) {
                this.closeDropDown();
            }
        });
    }

    ngOnInit() {
        this.updateHostClasses();
        const validators = this.validator ? [this.validator] : [];
        if (this.isRequired) {
            validators.push(Validators.required);
        }
        this.control = new FormControl({value: this._value, disabled: this.disabled}, Validators.compose(validators));
        if (this.formGroupRef) {
            this.formGroupRef.addControl(this.fieldName || getNewComponentId(), this.control);
        }
    }

    ngAfterViewInit() {
        this.checkParentScroll();
    }

    ngAfterViewChecked() {
        if (!this.readonly && !this._parentScroll) {
            this.checkParentScroll();
        }
    }


    ngOnDestroy() {
        // Remove the listeners!
        if (this._iframeClickedSubscription) {
            this._iframeClickedSubscription.unsubscribe();
            this._iframeClickedSubscription = null;
        }

        if (this._parentScroll) {
            this._parentScroll.removeEventListener('scroll', this.onParentScrollBind);
        }
    }

    ngOnChanges() {
        this.value = this._value;
    }

    onParentScroll(): void {
        // hide opened drop down when page is scrolled
        if (this.isOpen && this._parentScroll && this._lastScrollPos !== this._parentScroll.scrollTop) {
            this.closeDropDown();
        }
    }


    itemClicked(event: any, newValue: any) {
        event.preventDefault();
        event.stopPropagation();
        this.setValue(newValue);
    }

    public setValue(newValue: any) {
        if (newValue && this.valuePath) {
            newValue = newValue[this.valuePath];
        }
        if (this._value !== newValue) {
            this._value = newValue;
            if (this.control) {
                this.control.setValue(newValue);
            }
            this.valueChange.emit(newValue);
        }
    }

    public itemByValue(val: any): any {
        return this.items ? this.items.find(i => (this.valuePath ? i[this.valuePath]: i) === val) : null;
    }

    public inputEvent(evt:any, isUpMode:boolean = false):void {
        // tab
        if (evt.keyCode === 9) {
            return;
        }
        if (isUpMode && (evt.keyCode === 37 || evt.keyCode === 39 || evt.keyCode === 38 ||
            evt.keyCode === 40 || evt.keyCode === 13)) {
            evt.preventDefault();
            return;
        }
        // backspace
        if (!isUpMode && evt.keyCode === 8) {
            if (this._value) {
                this.setValue(null);
            }
            evt.preventDefault();
        }
        // esc
        if (!isUpMode && evt.keyCode === 27) {
            this.closeDropDown();
            this._element.nativeElement.children[0].focus();
            evt.preventDefault();
            return;
        }
        // del
        if (!isUpMode && evt.keyCode === 46) {
            if (this._value) {
                this.setValue(null);
            }
            evt.preventDefault();
        }
        // left
        if (!isUpMode && evt.keyCode === 37 && this.items.length > 0) {
            this.first();
            evt.preventDefault();
            return;
        }
        // right
        if (!isUpMode && evt.keyCode === 39 && this.items.length > 0) {
            this.last();
            evt.preventDefault();
            return;
        }
        // up
        if (!isUpMode && evt.keyCode === 38) {
            this.prev();
            evt.preventDefault();
            return;
        }
        // down
        if (!isUpMode && evt.keyCode === 40) {
            this.next();
            evt.preventDefault();
            return;
        }
        // enter
        if (!isUpMode && evt.keyCode === 13) {
            this.setValue(this.activeItem);
            this.closeDropDown();

            evt.preventDefault();
            return;
        }
        let target = evt.target || evt.srcElement;
        if (target && target.value) {
            const inputValue = target.value;
            this.selectItem(inputValue);
            this.clearInputValueWithTimeout();
        }
    }

    trackItem(index: number, item: IDropDownValue): string {
        return item.value;
    }

    private selectItem(typedValue: string) {
        const txtToSearch = typedValue.toLowerCase();
        const filteredItems = this.items.filter((item: any) => {
            return item.title.toLowerCase().indexOf(txtToSearch) === 0;
        });

        if (filteredItems.length) {
            this.activeItem = filteredItems[0];
            this.ensureHighlightVisible();
        }
    }

    private first():void {
        this.activeItem = this.items[0];
        this.ensureHighlightVisible();
    }

    private last():void {
        this.activeItem = this.items[this.items.length - 1];
        this.ensureHighlightVisible();
    }

    private prev():void {
        let index = this.items.indexOf(this.activeItem);
        this.activeItem = this.items[index - 1 < 0 ? this.items.length - 1 : index - 1];
        this.ensureHighlightVisible();
    }

    private next():void {
        let index = this.items.indexOf(this.activeItem);
        this.activeItem = this.items[index + 1 > this.items.length - 1 ? 0 : index + 1];
        this.ensureHighlightVisible();
    }

    private updateDropdownListPosition() {
        if (this._itemsContainer && this._parentScroll) {
            const dropDownContainer = this._itemsContainer.nativeElement;
            const dropDownBounds = this._element.nativeElement.getBoundingClientRect();
            const dropDownListBounds = dropDownContainer.getBoundingClientRect();
            const parentBounds = this._parentScroll.getBoundingClientRect();
            const maxRight = parentBounds.right - getElementScrollbarWidth(this._parentScroll);
            const margin = 5; // margin
            //console.log(`p: ${JSON.stringify(parentBounds)},\r\nd: ${JSON.stringify(dropDownListBounds)}`);
            dropDownContainer.style.maxWidth = `${maxRight - margin - dropDownListBounds.left}px`;
            // check vertical overflow
            if  (dropDownBounds.bottom + dropDownListBounds.height > parentBounds.bottom && (dropDownBounds.top - parentBounds.top) > parentBounds.height / 2) {
                // expand dropdown list to the top
                dropDownContainer.style.marginTop = `-${dropDownListBounds.height + this._element.nativeElement.offsetHeight}px`;
            }
            else {
                dropDownContainer.style.marginTop = 'auto';
            }
        }
    }

    private ensureHighlightVisible() {
        if (!this.activeItem) {
            return;
        }
        if (this._itemsContainer) {
            const container = this._itemsContainer.nativeElement;
            const choices = container.querySelectorAll('li');
            if (choices.length < 1) {
                return;
            }
            const activeIndex = this.items.indexOf(this.activeItem);
            const highlighted: any = choices[activeIndex];
            if (!highlighted) {
                return;
            }
            let posY: number = highlighted.offsetTop + highlighted.clientHeight - container.scrollTop;
            let height: number = container.offsetHeight;
            if (posY > height) {
                container.scrollTop += posY - height;
            } else if (posY < highlighted.clientHeight) {
                container.scrollTop -= highlighted.clientHeight - posY;
            }
        }
    }

    private updateHostClasses() {
        this.hostClasses = 'dropdown ' + (this._classNames || '');
    }

    private closeDropDown() {
        // close opened popup
        this.isOpen = false;
        this._changeDetectorRef.markForCheck();
    }

    private clearInputValueWithTimeout() {
        if (this._clearanceTimeout) {
            clearTimeout(this._clearanceTimeout);
        }
        this._clearanceTimeout = setTimeout(() => {
            this._inputEl.nativeElement.value = '';
        }, 1000);
    }

    private checkParentScroll() {
        if (this._itemsContainer) {
            this._parentScroll = getParentScroll(this._itemsContainer.nativeElement.parentNode);
            if (this._parentScroll) {
                this._zone.runOutsideAngular(() => {
                    this._parentScroll.addEventListener('scroll', this.onParentScrollBind);
                });
            }
        }
    }
}
