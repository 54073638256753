import { Component, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ContextMenuService } from './contextMenuService';
import { Subject } from 'rxjs';

export interface IContextMenuItem {
    title: string;
    icon?: string;
    data: any;
    subject: Subject<any>;
    fileInput?: boolean;
}

export interface IFileInputActionData {
    data: any;
    event: any;
}

@Component({
    selector: 'context-menu-holder',
    styleUrls: ['./contextMenuHolder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<div [ngStyle]="locationCss" class="cm_container">
                  <ul>
                    <li class="link" *ngFor="let link of links; trackBy: trackLink">
                        <div class="item" *ngIf="!link.fileInput" (click)="itemClicked(link)"><svg-icon *ngIf="link.icon" name="{{link.icon}}" className="menuIcon"></svg-icon><span class="menuText">{{link.title|trans}}</span></div>
                        <div class="item" *ngIf="link.fileInput"><input-file (fileAdded)="fileAdded(link, $event)"><svg-icon *ngIf="link.icon" name="{{link.icon}}" className="menuIcon"></svg-icon><span class="menuText">{{link.title|trans}}</span></input-file></div>
                    </li>
                  </ul>
                </div>
              `
})
export class ContextMenuHolderComponent {
    links: Array<IContextMenuItem> = [];
    isShown = false;
    private mouseLocation: {left: number, top: number} = {left: 0, top: 0};

    constructor(private _contextMenuService: ContextMenuService, private _changeDetectorRef: ChangeDetectorRef) {
        _contextMenuService.show.subscribe(e => this.showMenu(e.event, e.obj));
    }

    get locationCss() {
        return {
            'display': this.isShown ? 'block' : 'none',
            left: this.mouseLocation.left + 'px',
            top: this.mouseLocation.top + 'px',
        };
    }

    @HostListener('document:click')
    documentClick() {
        this.clickedOutside();
    }

    @HostListener('document:scroll')
    documentScroll() {
        this.clickedOutside();
    }

    @HostListener('document:contextmenu')
    documentContextMenu() {
        this.clickedOutside();
    }

    @HostListener('window:resize')
    windowResize() {
        this.clickedOutside();
    }

    clickedOutside() {
        this.isShown = false;
        this._changeDetectorRef.markForCheck();
    }

    showMenu(event: any, links: Array<IContextMenuItem>) {
        this.isShown = true;
        this.links = links;
        this.mouseLocation = {
            left: event.clientX,
            top: event.clientY
        };
        this._changeDetectorRef.markForCheck();
    }

    itemClicked(link: IContextMenuItem) {
        this.raiseItemClick(link);
    }

    fileAdded(link: IContextMenuItem, $event: any) {
        this.raiseItemClick(link, {data: link.data, event: $event});
    }

    raiseItemClick(link: IContextMenuItem, data?: any) {
        link.subject.next(data || link.data);
    }

    trackLink(index: number, link: IContextMenuItem): string {
        return link.data || link.title;
    }

}
