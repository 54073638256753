import { Pipe, PipeTransform } from '@angular/core';
import { IMultiSelectionItem } from './inputMultiSelection';

@Pipe({
    name: 'inputMultiSelectionItem'
})
export class InputMultiSelectionItemPipe implements PipeTransform {
    transform(choice: IMultiSelectionItem, values: Array<IMultiSelectionItem>){
        return values && values.indexOf && values.indexOf(choice) > -1;
    }
}
