import { Injectable } from '@angular/core';
import { BackendService } from 'ngx-myia-http';
import { Logger } from 'ngx-myia-core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IEventHistoryList } from '../entities/eventHistoryList.interface';

@Injectable({ providedIn: 'root' })
export class EventHistoryService {

    constructor(private _backendService: BackendService, private _logger: Logger) {
    }

    getEventHistory(culture: string): Observable<IEventHistoryList> {
        return this._backendService.get<IEventHistoryList>(`/events/history?lang=${culture}`)
            .pipe(
                // map(r => { // test 'no events' state
                //     r.events = [];
                //     return r;
                // }),
                catchError(err => {
                    this._logger.error('Could not load event history.');
                    return throwError(err);
                })
            );
    }
}
