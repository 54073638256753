import { addListener, getCanvasBlob, getElementOffset, parseHTML } from 'ngx-myia-core';
import { Observable, Observer, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as Cropper from 'cropperjs';

export interface ICroppedImageData {
    data: any;
    width: number;
    height: number;
}

@Injectable({providedIn: 'root'})
export class CroppingService {
    private _cropperLib: any;

    cropImageFromSrc(srcEl: HTMLElement, imageUrl: string, iframeEl: HTMLIFrameElement = null, useBlob = false, cropContainerClass: string = null, cropperOptions: any = null): Observable<ICroppedImageData> {
        return this.crop(srcEl, imageUrl, iframeEl, useBlob, cropContainerClass, cropperOptions);
    }

    cropImage(srcEl: HTMLElement, iframeEl: HTMLIFrameElement = null, useBlob = false, cropContainerClass: string = null, cropperOptions: any = null): Observable<ICroppedImageData> {
        const imageUrl = srcEl.getAttribute('src');
        return this.crop(srcEl, imageUrl, iframeEl, useBlob, cropContainerClass, cropperOptions);
    }

    private getIconHtml(iconName: string): string {
        return `<span class="icon"><svg xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg"><use xlink:href="#svgIcon-${iconName}"></use></svg></span>`;
    }

    private crop(srcEl: HTMLElement, imageUrl: string, iframeEl: HTMLIFrameElement, useBlob: boolean, cropContainerClass: string = null, cropperOptions: any = null): Observable<ICroppedImageData> {
        let cropper: Cropper;
        let imgElRoot: HTMLElement;
        const destroyCropper = () => {
            if (cropper) {
                cropper.destroy();
            }
            if (imgElRoot) {
                imgElRoot.remove();
            }
            // show original image element
            srcEl.style.visibility = 'visible';
        };

        return new Observable((observer: Observer<any>) => {
            let options = {
                modal: false,
                autoCropArea: 100, // initial crop size
                guides: false,
                background: false, // hide chessboard background
                minContainerWidth: 50,
                minContainerHeight: 50
                // aspectRatio: 16 / 9,
            };
            if (cropperOptions) {
                options = {...options, ...cropperOptions};
            }
            const iframePos = iframeEl ? getElementOffset(iframeEl) : {left: 0, top: 0};
            imgElRoot = parseHTML(`<div class="croppedImageContainer ${cropContainerClass}"><div class="croppedImageContainerInner"><img src="${imageUrl}" style="width:100%;height:100%;"/><div class="cropperButtons"><button class="zoomIn">${this.getIconHtml('zoom-in')}</button><button class="zoomOut">${this.getIconHtml('zoom-out')}</button><span class="delim"></span><button class="accept">${this.getIconHtml('ok')}</button><button class="cancel">${this.getIconHtml('cancel')}</button></div></div></div>`).body.firstElementChild as HTMLElement;
            const imgEl = imgElRoot.querySelector('.croppedImageContainerInner') as HTMLElement;
            const srcElPos = getElementOffset(srcEl);
            imgEl.style.left = `${iframePos.left + srcElPos.left}px`;
            imgEl.style.top = `${iframePos.top + srcElPos.top}px`;
            imgEl.style.width = `${srcEl.clientWidth}px`;
            imgEl.style.height = `${srcEl.clientHeight}px`;
            const cropperEl = imgEl.querySelector('img');
            addListener(cropperEl, ['ready'], () => {
                // show img when cropper built
                imgElRoot.classList.add('show');
            });
            addListener(cropperEl, ['load'], () => {
                this.loadCropperLib().subscribe(() => {
                    cropper = new this._cropperLib(cropperEl as HTMLImageElement, options);
                });
            });
            document.body.appendChild(imgElRoot);

            // hide original image element to avoid overflow when its transparent PNG
            srcEl.style.visibility = 'hidden';

            addListener(imgElRoot.querySelector('.zoomIn'), ['click'], () => {
                cropper.zoom(0.1);
            });
            addListener(imgElRoot.querySelector('.zoomOut'), ['click'], () => {
                cropper.zoom(-0.1);
            });
            addListener(imgElRoot.querySelector('.accept'), ['click'], () => {
                const cropBoxData = cropper.getCropBoxData();
                const croppedCanvas = cropper.getCroppedCanvas();
                if (useBlob) {
                    getCanvasBlob(croppedCanvas).subscribe(blob => {
                        const result = {
                            data: blob,
                            width: cropBoxData.width,
                            height: cropBoxData.height
                        };
                        observer.next(result);
                        observer.complete();
                    });
                } else {
                    const result = {
                        data: croppedCanvas.toDataURL(),
                        width: cropBoxData.width,
                        height: cropBoxData.height
                    };
                    observer.next(result);
                    observer.complete();
                }
            });
            addListener(imgElRoot.querySelector('.cancel'), ['click'], () => {
                observer.next(null);
                observer.complete();
            });
            // setTimeout(() => {
            //     resolve(imageUrl);
            // }, 2000);
        }).pipe(
            finalize(() => {
                // cleanup resources
                destroyCropper();
            })
        );
    }

    private loadCropperLib(): Observable<void> {
        return this._cropperLib ? of(null) : new Observable((observer: Observer<void>) => {
            import('cropperjs').then(cropperJsLib => {
                this._cropperLib = cropperJsLib.default;
                observer.next(null);
                observer.complete();
            });
        });
    }
}

export const croppingService = new CroppingService();
